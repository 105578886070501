<template>
    <div id="property">
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div class="property-container" v-else>
            <PageTitle :title="data.Title" template="property" :bg="headerImage" :tag="data.Tag | startCase"/>
            <div class="title-container">
                <p>
                    {{data.Address}} {{data.Area}} {{data.Zip}} / {{data.Price | priceFormat}} <span v-if="data.Lease"> / mo.</span>
                </p>
            </div>
            <div id="slides">
                <modal name="example"
                    class="modal-container"
                    :adaptive="true" 
                    :width="window.width > 576 ? '80%' : '95%'"
                    :height="window.width > 576 ? '100%' : '95vw'">
                    <div class="modal-content">
                        <button @click="turnOffModal">
                            <img src="/images/lightbox-close.svg" class="close-icon"/>
                        </button>
                        <vueper-slides
                            class="modal-slideshow no-shadow"
                            :bullets="true"
                            :arrows="false"
                            :slide-ratio="1 / 4"
                            :dragging-distance="70"
                            :fixed-height="window.width > 576 ? '80vh' : '95vw'"
                            prevent-y-scroll>
                        <vueper-slide
                            v-for="(slide, i) in data.FloorPlans"
                            :key="i"
                            :image="url+slide.url"
                            class="floor-plan-slide"
                            >
                        </vueper-slide>
                    </vueper-slides>
                    </div>
                </modal>
                <modal name="underbuilt"
                    class="modal-container"
                    :adaptive="true" 
                    :width="window.width > 576 ? '80%' : '95%'"
                    :height="window.width > 576 ? '100%' : '95vw'">
                    <div class="modal-content">
                        <button @click="turnOffUnderbuiltModal">
                            <img src="/images/lightbox-close.svg" class="close-icon"/>
                        </button>
                        <vueper-slides
                            class="modal-slideshow no-shadow"
                            :bullets="true"
                            :arrows="false"
                            :slide-ratio="1 / 4"
                            :dragging-distance="70"
                            :fixed-height="window.width > 576 ? '80vh' : '95vw'"
                            prevent-y-scroll>
                        <vueper-slide
                            v-for="(slide, i) in data.Underbuilt"
                            :key="i"
                            :image="url+slide.url"
                            class="floor-plan-slide"
                            >
                        </vueper-slide>
                    </vueper-slides>
                    </div>
                </modal>
                <vueper-slides
                        :bullets="false"
                        @slide="activeThumbnail"
                        ref="vueperslides1"
                        id="mainSlides"
                        :slide-ratio="1 / 4"
                        :dragging-distance="70"
                        :fixed-height="adaptiveHeight"
                        prevent-y-scroll>
                    <vueper-slide
                        v-for="(slide, i) in data.Carousel"
                        :key="i"
                        :image="url+slide.url"
                        class="main_slides"
                        >
                    </vueper-slide>
                </vueper-slides>
                <div class="floor-plan-container">
                    <a href="#" v-if="data.FloorPlans && data.FloorPlans.length > 0" @click.prevent="toggleModal" class="floor-plan-button">
                        Floor Plan
                        <img class="floorplan-image" src="/images/floorplan.svg" alt="">
                    </a>
                    <a href="#" v-if="data.Underbuilt && data.Underbuilt.length > 0" @click.prevent="toggleUnderbuiltModal" class="floor-plan-button">
                        Underbuilt
                        <img class="floorplan-image" src="/images/underbuilt.svg" alt="">
                    </a>
                </div>
                <vueper-slides
                    v-if="data.Carousel.length > 7 && window.width > 576"
                    class="thumbnails"
                    ref="vueperslides2"
                    :slide-ratio="1 / 8"
                    :dragging-distance="50"
                    @slide="activeSlide"
                    :visible-slides="Math.ceil(window.width/100)"
                    :bullets="false"
                    :arrows="false"
                    :fixed-height="window.width > 576 ? '80px' : '50px'">
                    <vueper-slide
                        v-for="(slide, i) in data.Carousel"
                        :key="i"
                        @click.native="$refs.vueperslides2.goToSlide(i)"
                        >
                        <template v-slot:content>
                            <div class="thumbnail-slide">
                                <img :src="url+slide.formats.thumbnail.url" />
                            </div>
                        </template>
                    </vueper-slide>
                </vueper-slides>
            </div>
            <div class="property-content" :class="{'has-floors' : data.FloorPlans && data.FloorPlans.length}">
                <div class="property-description">
                    <h2>
                        {{data.DescriptionTitle}}
                    </h2>
                    <p v-html="data.Description">
                        {{data.Description}}
                    </p>
                </div>
                <div class="property-data">
                    <h3>
                        {{data.Price | priceFormat}} <span v-if="data.Lease"> / mo.</span>
                    </h3>
                    <h3>
                        {{data.Bedrooms}} BEDS
                    </h3>
                    <h3>
                        {{data.Bathrooms}} BATHS
                    </h3>
                    <h3>
                        {{data.Size}} SQFT
                    </h3>
                </div>
            </div>
            <div v-if="data.VideoID" class='embed-container'>
                <iframe width="100%" :src="'https://www.youtube.com/embed/'+ data.VideoID" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import PageTitle from "../components/PageTitle"
import { VueperSlides, VueperSlide } from 'vueperslides'

export default {
    name: "property",
    components: {
        PageTitle,
        Spinner,
        VueperSlides, 
        VueperSlide,
    },
    data(){
        return{
            title: "Paul Salazar | Property",
            currentIndex: 0,
            loading: true,
            nodatafound: false,
            data: {},
            url: 'https://strapi.uptowncreative.io',
            images:[],
            window: {
                width: 0,
                height: 0
            },
        }
    },
    filters: {
        priceFormat: function (value) {
            if (!value) return ''
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            })
            return formatter.format(value)
        }
    },
    computed: {
        adaptiveHeight: function(){
            if (this.window.width < 1100 & this.window.height > 1000){
                return '40vh';
            }
            if (this.window.width > 576){
                return '80vh';
            } else {
                return '300px';
            }
        },
        headerImage: function(){
            if (this.data.HeaderImage){
                return this.url+ this.data.HeaderImage.url
            } else if(this.data.DisplayImage){
                return this.url + this.data.DisplayImage.url
            }
            return '';
        }
    },
    methods: {
        activeThumbnail(event){
            this.$refs.vueperslides2.goToSlide(event.currentSlide.index, { emit: false })
            this.currentIndex = event.currentSlide.index;

        },
        activeSlide(event){
            this.$refs.vueperslides1.goToSlide(event.currentSlide.index, { emit: false })
            this.currentIndex = event.currentSlide.index;
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        toggleModal() {
            this.$modal.show('example');
        },
        turnOffModal() {
            this.$modal.hide('example');
        },
        toggleUnderbuiltModal() {
            this.$modal.show('underbuilt');
        },
        turnOffUnderbuiltModal() {
            this.$modal.hide('underbuilt');
        }
    },
    mounted:function(){
        this.axios.get(`https://strapi.uptowncreative.io/paul-salazar-properties?Slug=${this.$route.params.slug}`)
        .then((response) => {
            this.data = response.data[0];
            this.loading = false;
        })
    },
    metaInfo() {
         let data = this.title; 
         return {
             title: data ? data : "Property",
             titleTemplate: '%s | Paul Salazar',
             meta: [{
                name: 'description', 
                content: "Paul Salazar"
            }]
         }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss" scoped>
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin: 2rem 4rem;
}
		
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.property-container{
    margin-bottom: 6rem;
}
.title-container{
    text-align: right;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background: #1a1a1a;
    text-transform: uppercase;
    height: 20vh;
}
#slides{
    position: relative;
}
.floor-plan-container{
    position: absolute;
    bottom: 100px;
    z-index: 2;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    left: 50%;
    transform:translateX(-50%);
    .floor-plan-button{
        display: flex;
        align-items: center;
        margin-right: 30px;
        background: #000;
        padding: 8px 15px;
    }
    .floorplan-image{
        display: inline-block;
        height: 20px;
        margin-left: 20px;
    }
}
.thumbnails{
    margin-bottom: 1rem;
    .vueperslide{
        padding: 0 20px;
        z-index: 10;
        padding-top: 10px;
    }
}
.thumbnail-slide{
    padding-top: 10px;
    img{
        width: 80px;
        height: 60px;
        object-fit: cover;
    }
}
.property-content{
    margin: 4rem;
    display: flex;
    align-items: center;
    color: #fff;
}
.property-description{
    width: 75%;
    padding-right: 60px;
    border-right: 1px solid rgba(255,255,255,0.5);
    h2{
        font-weight: 100;
        letter-spacing: 2px;
        font-size: 60px;
        text-transform: uppercase;
    }
}
.property-data{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 60px;
    h3{
        letter-spacing: 3px;
        font-weight: 500;
        font-size: 18px;
    }
}

.modal-content{
    button{
        background: transparent;
        border: none;
        position: absolute;
        right: 20px;
        top: 20px;
        &:hover{
            cursor: pointer;
        }
    }
    .close-icon{
        width: 40px;
        height: 40px;
    }
}
.modal-slideshow{
    margin: 4rem 4rem;
}

.floor-plan-slide{
    background-size: contain;
    background-repeat: no-repeat;
}
@media (max-width: $mobile) { 
    .title-container{
        padding: 3rem 2rem;
    }
    .property-content{
        margin: 2rem;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        &.has-floors{
            margin-top: 100px;
        }
    }
    .property-description{
        width: 100%;
        padding-right: unset;
        border-right: unset;
        h2{
            font-weight: 100;
            letter-spacing: 2px;
            font-size: 40px;
            text-transform: uppercase;
        }
    }
    .property-data{
        margin: 2rem 0;
        h3{
            letter-spacing: 3px;
            font-weight: 500;
            font-size: 18px;
        }
    }
    .modal-slideshow{
        margin: 0;
        margin-top: 4rem;
        margin-bottom: 2rem;
    }
    .modal-container{
        top: 80px;
    }
    .floor-plan-container{
        flex-direction: column;
        bottom: -120px;
        .floor-plan-button{
            margin-top: 10px;
            border: 1px solid #ccc;
            margin-right: 0;
        }
    }
    .embed-container {
        margin: 2rem 0;
    }
}
</style>

<style lang="scss">
#mainSlides{
    .vueperslides__arrow--prev{
        left: 1.5em;
    }
    .vueperslides__arrow--next{
        right: 1.5em;
    }
}
</style>