<template>
    <div class="title" v-bind:style="styleObject" :class="template">
        <h1>
            {{title}}
        </h1>
        <span v-if="tag" class="tag" :class="tag">
            {{ tag }}
        </span>
    </div>
</template>

<script>
export default {
    name:"PageTitle",
    props: ['title', 'bg', 'tag', 'template'],
    data(){
        return {
            styleObject: {
                background: this.bg ?  `url("${this.bg}")` : 'url("/images/header.jpg")',
                backgroundSize: 'cover',
            }
        }
    }
}
</script>

<style lang="scss" scoped>
div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    margin-top: -60px;
    border-bottom: 1px solid #353536;
    align-items: flex-start;
    align-content: flex-start;
    &::before{
        content: ""; // ::before and ::after both require content
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 90vh;
        background-image: linear-gradient(#000, #000);
        opacity: .3;
        border-bottom: 1px solid #353536;
    }
    &.property{
        height: 80vh;
        &::before{
            height: 80vh;
        }
    }
    &.full{
        height: 100vh;
        &::before{
            content: ""; // ::before and ::after both require content
            background: none;
            height: 100vh;
        }
    }
}
h1{
    font-size: 65px;
    text-align: left;
    letter-spacing: 2px;
    padding-top: 2rem;
    font-weight: 100;
    color: #fff;
    text-transform: uppercase;
    z-index: 2; 
    margin: 0;
    margin-left: 4rem;
}
.tag{
    margin-left: 4rem;
    display: block;
    opacity: 1;
    z-index: 5;
    &.Past, &.Sold{
        background: #fff;
        color: #000;
    }
}

@media (max-width: $tablet) {
    div{
        margin-top: 0;
        height: 90vh;
        max-height: unset;
        &::before{
            margin-top: 60px;
            height: 90vh;
            max-height: unset;
        }
        &.property{
            height: 80vh;
            &::before{
                height: 80vh;
            }
        }
    }
    h1{
        padding-top: 0;
    }
}
@media (max-width: $mobile) {
    div{
        height: 100vw;
        &::before{
            height: 100vw;
        }
        &.property{
            height: 80vh;
            &::before{
                height: 80vh;
            }
        }
        background-position: 63% 25% !important;
        border-bottom:none;
        &::before{
            content: ""; // ::before
            border-bottom: none;
        }
    }
    h1{
        margin-left: 2rem;
        font-size: 55px;
        line-height: 1;
    }
    .tag{
        margin-left: 2rem;
    }
}
</style>
